<template>
  <v-list dense>
    <v-list-group
      v-for="item in menu"
      :key="item.path"
    >
      <template #activator>
        <v-list-item-content>
          <v-list-item-title v-text="item.props.title" />
        </v-list-item-content>
      </template>

      <v-list-item
        v-for="childItem in item.children"
        :key="childItem.path"
        :to="{ name: childItem.name }"
        link
        class="py-2"
      >
        <v-list-item-icon>
          <v-icon size="15">
            {{ childItem.props.icon }}
          </v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>
            {{ childItem.props.title }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list-group>
  </v-list>
</template>

<script>
import { mapGetters } from 'vuex';
import mainRoutes from '@/router/main';
import validationRole from '../../misc/validation-role';

export default {
  name: 'MenuItems',
  computed: {
    ...mapGetters('auth', ['roles']),
    menu() {
      return mainRoutes.children.filter((route) => {
        const result = validationRole(route, this.roles);
        if (result.length > 0) {
          return route;
        }
      });
    },
  },
};
</script>
