import Vue from 'vue';

import Notifier from '@/components/shared/Notifier';
import Gallery from '@/components/shared/Gallery';
import IconButton from './IconButton';
import Loader from './Loader';
import KosmoEditor from './kosmoEditor';

Vue.component('IconButton', IconButton);
Vue.component('Loader', Loader);
Vue.component('Notifier', Notifier);
Vue.component('Gallery', Gallery);
Vue.component('KosmoEditor', KosmoEditor);
