<template>
  <div>
    <v-row
      v-if="images.length > 0"
      no-gutters
      class="px-5"
    >
      <v-col
        v-for="(image, index) in images"
        :key="index"
        class="d-flex mr-3"
        lg="2"
      >
        <v-img
          :src="image"
          aspect-ratio="1"
          class="grey lighten-2"
        >
          <v-btn
            v-if="disableDeleteButton === false"
            icon
            color="grey"
            @click="onDeleteImage(index)"
          >
            <v-icon elevation="15">
              mdi-close-circle
            </v-icon>
          </v-btn>
          <template #placeholder>
            <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
            >
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              />
            </v-row>
          </template>
        </v-img>
      </v-col>
    </v-row>
    <div
      v-else
      class="d-flex justify-center"
    >
      <p class="grey--text text-uppercase lighten-5">
        Nenhuma foto adicionada
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Gallery',
  props: {
    images: {
      type: Array,
      default: () => [],
    },
    disableDeleteButton: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    onDeleteImage(index) {
      this.$emit('image-deleted', index);
    },
  },
};
</script>

<style scoped>

</style>
