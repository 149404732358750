import { render, staticRenderFns } from "./Loader.vue?vue&type=template&id=5a806ca2&scoped=true"
import script from "./Loader.vue?vue&type=script&lang=js"
export * from "./Loader.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a806ca2",
  null
  
)

export default component.exports