import Vue from 'vue';
import VueGtag from 'vue-gtag';
import VueTheMask from 'vue-the-mask';
import setupInterceptors from '@/misc/axios-interceptors';
import toast from '@/misc/toast';
import httpErrorHandler from '@/misc/error';
import rules from '@/misc/rules';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import './styles/global.css';
import './components/shared';
import './misc/filters';

Vue.config.productionTip = false;

Object.keys(VueTheMask).forEach((key) => {
  Vue.directive(key, VueTheMask[key]);
});

Vue.prototype.$toast = toast;
Vue.prototype.$handleHttpError = httpErrorHandler;
Vue.prototype.$rules = rules;

Vue.use(VueGtag, {
  config: {
    id: 'G-JJC09THMX2',
  },
}, router);

new Vue({
  router,
  store,
  vuetify,
  created() {
    setupInterceptors(store);
  },
  render: (h) => h(App),
}).$mount('#app');
