<template>
  <v-container
    fluid
    fill-height
    align-center
  >
    <v-row justify="center">
      <v-col
        cols="12"
        sm="8"
        md="6"
        lg="4"
      >
        <v-card>
          <v-card-title class="primary white--text d-flex justify-center">
            <span>{{ pageName }} - Área restrita</span>
          </v-card-title>
          <transition
            name="fade"
            mode="out-in"
          >
            <router-view />
          </transition>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  computed: {
    pageName() {
      return this.$route.matched[this.$route.matched.length - 1].props.default.title;
    },
  },
};
</script>
