import Vue from 'vue';
import Vuex from 'vuex';

import auth from './auth.module';
import loader from './loader.module';
import notifier from './notifier.module';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    loader,
    notifier,
  },
});
