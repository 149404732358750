import api from './api';

/* eslint-disable-next-line import/prefer-default-export */
export const image = (value) => {
  const headers = {
    'Content-Type': 'multipart/form-data',
  };

  const formData = new FormData();
  formData.append('files', value);

  return api.post('upload/image', formData, headers);
};
