<template>
  <div class="d-flex flex-column text-uppercase font-weight-thin subtitle-2">
    <span>CM ADMIN</span>
  </div>
</template>

<script>
export default {

};
</script>

<style>

</style>
