import api from '../services/api';

const actionScope = 'loader';

export default function setupInterceptors({ dispatch }) {
  let requestsPending = 0;

  const req = {
    pending: () => {
      requestsPending++;
      dispatch(`${actionScope}/show`);
    },
    done: () => {
      requestsPending--;
      if (requestsPending <= 0) {
        dispatch(`${actionScope}/hide`);
      }
    },
  };

  api.interceptors.request.use(
    (config) => {
      req.pending();
      return config;
    },
    (error) => {
      requestsPending--;
      req.done();
      return Promise.reject(error);
    },
  );

  api.interceptors.response.use(
    ({ data }) => {
      req.done();
      return Promise.resolve(data);
    },
    (error) => {
      req.done();
      return Promise.reject(error);
    },
  );

  api.interceptors.request.use((config) => {
    const token = JSON.parse(window.localStorage.getItem('cassems_token'));
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  });
}
