import router from '@/router';

const state = {
  token: JSON.parse(window.localStorage.getItem('cassems_token')),
  user: window.localStorage.getItem('cassems_user'),
  roles: [],
};

const mutations = {
  login(state, payload) {
    window.localStorage.setItem('cassems_token', JSON.stringify(payload.token));
    state.token = payload.token;
    window.localStorage.setItem('cassems_user', payload.name);
    state.user = payload.name;
    state.roles = payload.roles;
  },
  logout(state) {
    window.localStorage.removeItem('cassems_token');
    window.localStorage.removeItem('cassems_user');
    state.token = undefined;
    state.user = undefined;
    state.roles = undefined;
  },
};

const actions = {
  async login({ commit }, payload) {
    commit('login', payload);
  },
  async logout({ commit }) {
    commit('logout');
    router.push({ name: 'auth.login' });
  },
};

const getters = {
  isLogged(state) {
    return !!state.token;
  },
  token(state) {
    return state.token;
  },
  userName(state) {
    return state?.user;
  },
  roles(state) {
    return state?.roles;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
