import Main from '@/layouts/Main';
import Roles from './roles';

export default {
  path: '/',
  redirect: { name: 'auth.login' },
  component: Main,
  children: [
    {
      path: 'home',
      name: 'main.home',
      component: () => import('@/views/main/Home.vue'),
      meta: { requiresLogin: true, roles: [Roles.default] },
      props: {
        title: 'Início',
        icon: 'mdi-home',
        hide: false,
      },
    },
    {
      path: 'informa',
      name: 'main.informa',
      component: () => import('@/pages/Informa'),
      meta: {
        requiresLogin: true,
        roles: [Roles.administrator, Roles.communication, Roles.rh],
      },
      props: {
        title: 'Informa',
        hide: false,
      },
      children: [
        {
          path: 'gallerys',
          name: 'main.gallerys',
          component: () => import('@/views/main/gallerys/List'),
          meta: {
            requiresLogin: true,
            roles: [Roles.administrator, Roles.communication],
          },
          props: {
            title: 'Galeria',
            icon: 'mdi-camera-burst',
            hide: false,
          },
        },
        {
          path: 'news',
          name: 'main.news',
          component: () => import('@/views/main/news/List'),
          meta: {
            requiresLogin: true,
            roles: [Roles.administrator, Roles.communication],
          },
          props: {
            title: 'Notícias',
            icon: 'mdi-newspaper',
            hide: false,
          },
        },
        {
          path: 'articles',
          name: 'main.articles',
          component: () => import('@/views/main/articles/List'),
          meta: {
            requiresLogin: true,
            roles: [Roles.administrator, Roles.communication],
          },
          props: {
            title: 'Artigos',
            icon: 'mdi-newspaper',
            hide: false,
          },
        },
        {
          path: 'lgpd',
          name: 'main.lgpd',
          component: () => import('@/views/main/lgpd/List'),
          meta: {
            requiresLogin: true,
            roles: [Roles.administrator, Roles.lgpd],
          },
          props: {
            title: 'LGPD',
            icon: 'mdi-newspaper',
            hide: false,
          },
        },
        {
          path: 'compliance',
          name: 'main.compliance',
          component: () => import('@/views/main/compliance/List'),
          meta: {
            requiresLogin: true,
            roles: [Roles.administrator, Roles.communication],
          },
          props: {
            title: 'Compliance',
            icon: 'mdi-newspaper',
            hide: false,
          },
        },
        {
          path: 'compliance-category',
          name: 'main.compliancecategory',
          component: () => import('@/views/main/compliance-category/List'),
          meta: {
            requiresLogin: true,
            roles: [Roles.administrator, Roles.communication],
          },
          props: {
            title: 'Categorias Compliance',
            icon: 'mdi-card-text-outline',
            hide: false,
          },
        },
        {
          path: 'notice-board',
          name: 'main.notice-board',
          component: () => import('@/views/main/notice-board/List'),
          meta: {
            requiresLogin: true,
            roles: [Roles.administrator, Roles.communication],
          },
          props: {
            title: 'Mural de Avisos',
            icon: 'mdi-clipboard-text-play',
            hide: false,
          },
        },
        {
          path: 'collaborator-category',
          name: 'main.collaborator-category',
          component: () => import('@/views/main/collaborator-category/List'),
          meta: {
            requiresLogin: true,
            roles: [Roles.administrator, Roles.communication],
          },
          props: {
            title: 'Categoria de Colaboradores',
            icon: 'mdi-account-multiple',
            hide: false,
          },
        },
        {
          path: 'drugstore-partner',
          name: 'main.drugstore-partner',
          component: () => import('@/views/main/drugstore-partner/List'),
          meta: {
            requiresLogin: true,
            roles: [Roles.administrator, Roles.communication],
          },
          props: {
            title: 'Farmácias Parceiras',
            icon: 'mdi-handshake-outline',
            hide: false,
          },
        },
        {
          path: 'suggestions',
          name: 'main.suggestions',
          component: () => import('@/views/main/suggestions/List'),
          meta: { requiresLogin: true, roles: [Roles.rh] },
          props: {
            title: 'Sugestões e Reclamações',
            icon: 'mdi-bullhorn',
            hide: false,
          },
        },
        {
          path: 'promotions',
          name: 'main.promotions',
          component: () => import('@/views/main/promotions/List'),
          meta: {
            requiresLogin: true,
            roles: [Roles.vagas, Roles.administrator],
          },
          props: {
            title: 'Promoções',
            icon: 'mdi-account-arrow-up',
            hide: false,
          },
        },
        {
          path: 'jobs',
          name: 'main.jobs',
          component: () => import('@/views/main/jobs/List'),
          meta: {
            requiresLogin: true,
            roles: [Roles.vagas, Roles.administrator],
          },
          props: {
            title: 'Vagas Internas',
            icon: 'mdi-trending-up',
            hide: false,
          },
        },
        {
          path: 'candidates',
          name: 'main.candidates',
          component: () => import('@/views/main/candidates/List'),
          meta: {
            requiresLogin: true,
            roles: [Roles.vagas, Roles.administrator],
          },
          props: {
            title: 'Candidatos Cadastrados',
            icon: 'mdi-account-group',
            hide: false,
          },
        },
      ],
    },
    {
      path: 'portais-cassems',
      name: 'main.portaiscassems',
      component: () => import('@/pages/PortaisCassems'),
      meta: {
        requiresLogin: true,
        roles: [Roles.administrator, Roles.support],
      },
      props: {
        title: 'Portais Cassems',
        hide: false,
      },
      children: [
        {
          path: 'change-password',
          name: 'main.changepassword',
          component: () => import('@/views/main/change-password/Stepper'),
          meta: {
            requiresLogin: true,
            roles: [Roles.administrator, Roles.support],
          },
          props: {
            title: 'Alterar Senha',
            icon: 'mdi-key-outline',
            hide: false,
          },
        },
        {
          path: 'change-email',
          name: 'main.changeemail',
          component: () => import('@/views/main/change-email/Form'),
          meta: {
            requiresLogin: true,
            roles: [Roles.administrator, Roles.support],
          },
          props: {
            title: 'Alterar E-mail',
            icon: 'mdi-email-outline',
            hide: false,
          },
        },
        {
          path: 'campaigns',
          name: 'main.campaigns',
          component: () => import('@/views/main/campaigns/List'),
          meta: {
            requiresLogin: true,
            roles: [Roles.administrator, Roles.support],
          },
          props: {
            title: 'Campanhas',
            icon: 'mdi-newspaper',
            hide: false,
          },
        },
        {
          path: 'regulations',
          name: 'main.regulations',
          component: () => import('@/views/main/regulations/List'),
          meta: {
            requiresLogin: true,
            roles: [Roles.administrator, Roles.credenciamento],
          },
          props: {
            title: 'Regulamentações e Carências',
            icon: 'mdi-book-refresh-outline',
            hide: false,
          },
        },
      ],
    },
    {
      path: 'general-contents',
      name: 'main.generalcontents',
      component: () => import('@/pages/GeneralContents'),
      meta: {
        requiresLogin: true,
        roles: [Roles.administrator, Roles.communication],
      },
      props: {
        title: 'Conteúdos Gerais',
        hide: false,
      },
      children: [
        {
          path: 'service-units',
          name: 'main.serviceunits',
          component: () => import('@/views/main/service-units/List'),
          meta: {
            requiresLogin: true,
            roles: [Roles.administrator, Roles.communication],
          },
          props: {
            title: 'Unidades de Atendimento',
            icon: 'mdi-hospital-building',
            hide: false,
          },
        },
        {
          path: 'unit-categories',
          name: 'main.unitcategories',
          component: () => import('@/views/main/unit-categories/List'),
          meta: {
            requiresLogin: true,
            roles: [Roles.administrator, Roles.communication],
          },
          props: {
            title: 'Categorias das Unidades de Atendimento',
            icon: 'mdi-hospital-building',
            hide: false,
          },
        },
        {
          path: 'unit-city',
          name: 'main.unitcity',
          component: () => import('@/views/main/units-city/List'),
          meta: {
            requiresLogin: true,
            roles: [Roles.administrator, Roles.communication],
          },
          props: {
            title: 'Cidades das Unidades de Atendimento',
            icon: 'mdi-city-variant-outline',
            hide: false,
          },
        },
        {
          path: 'target-product',
          name: 'main.targetProduct',
          component: () => import('@/views/main/target-product/List'),
          meta: {
            requiresLogin: true,
            roles: [Roles.administrator, Roles.communication],
          },
          props: {
            title: 'Produtos Cassems',
            icon: 'mdi-city-variant-outline',
            hide: false,
          },
        },
        {
          path: 'category',
          name: 'main.category',
          component: () => import('@/views/main/category/List'),
          meta: {
            requiresLogin: true,
            roles: [Roles.administrator, Roles.communication],
          },
          props: {
            title: 'Categorias',
            icon: 'mdi-card-text-outline',
            hide: false,
          },
        },
        {
          path: 'institutional-text',
          name: 'main.institutionaltext',
          component: () => import('@/views/main/institutionalText/List'),
          meta: {
            requiresLogin: true,
            roles: [Roles.administrator, Roles.communication],
          },
          props: {
            title: 'Textos Institucionais',
            icon: 'mdi-script-text-outline',
            hide: false,
          },
        },
        {
          path: 'providers',
          name: 'main.providers',
          component: () => import('@/views/main/providers/List'),
          meta: {
            requiresLogin: true,
            roles: [Roles.administrator, Roles.support],
          },
          props: {
            title: 'Credenciados',
            icon: 'mdi-newspaper',
            hide: false,
          },
        },
      ],
    },
  ],
};
