const Roles = {
  default: 'cm-admin',
  support: 'cm-altera-senha',
  communication: 'cm-comunic',
  administrator: 'cm-root',
  rh: 'cm-rh',
  vagas: 'cm-vagas',
  credenciamento: 'cm-credenciamento',
  lgpd: 'cm-lgpd'
};

export default Roles;
