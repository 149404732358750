<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <span class="mb-2">
          {{ label }}
        </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <vue-editor
          v-model="content"
          class="mt-3"
          :editor-toolbar="customToolbar"
          use-custom-image-handler
          @image-added="handleImageAdded"
          @input="onChange"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <span
          class="mb-2"
          :style="{ color: value.length > maxLength ? 'red' : '' }"
        >
          {{ value.length }} / {{ maxLength }}
        </span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { VueEditor } from 'vue2-editor';
import { image } from '@/services/upload-service';

export default {
  components: {
    VueEditor,
  },

  props: {
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    maxLength: {
      type: Number,
      default: 4000,
    },
    customToolbar: {
      type: Array,
      default: () => [
        [{ font: [] }],
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
        ['code-block'],
        [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
        [{ script: 'sub' }, { script: 'super' }],
        [{ color: [] }, { background: [] }],
        ['link', 'image', 'video'],
        [{ direction: 'rtl' }],
        ['clean'],
      ],
    },
  },

  data() {
    return {
      content: this.value,
    };
  },
  watch: {
    value(text) {
      if (text.length > this.maxLength) {
        this.$toast.error('Limite de caracteres atingido no campo de texto!');
      }
    },
  },
  methods: {
    onChange(value) {
      this.$emit('input', value);
    },
    async handleImageAdded(file, Editor, cursorLocation, resetUploader) {
      const { data } = await image(file);

      Editor.insertEmbed(cursorLocation, 'image', data);
      resetUploader();
    },
  },
};
</script>

<style scoped>
</style>
