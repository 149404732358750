import Vue from 'vue';
import VueRouter from 'vue-router';
import toast from '@/misc/toast';
import main from './main';
import auth from './auth';
import store from '../store';
import validationRole from '../misc/validation-role';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [main, auth],
});

router.beforeEach((to, from, next) => {
  if (to.meta.requiresLogin && !store.getters['auth/isLogged']) {
    store.dispatch('auth/logout');
    return;
  }

  if (!to.meta.roles) {
    next(next);
    return;
  }

  const result = validationRole(to, store.getters['auth/roles']);

  if (result.length === 0) {
    toast.error('Você não possui permissão para acessar essa página.');
    next(from);
    return;
  }

  next(next);
});

export default router;
