const rules = {
  required: [(v) => !!v || 'Campo obrigatório'],
  email: [(v) => /.+@.+/.test(v) || 'E-mail inválido'],
  dataRules: [
    (v) => !!v || 'Data obrigatório',
    (v) => /^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/.test(v) || 'Data formato inválido ',
  ],

  confirmPassword(password1, password2) {
    return [() => password1 === password2 || 'Senhas diferentes'];
  },
  cpf: [(v) => String(v).length === 11 || 'CPF inválido'],
  onlyNumbers: [(v) => Number(v) || 'Este campo deve conter apenas números'],
  selectRequired: [(v) => v.length > 0 || 'Selecione ao menos um Item'],
};

export default rules;
