<template>
  <v-snackbar
    v-model="visible"
    :color="type"
    :timeout="4000"
    @input="hide"
  >
    {{ message }}
  </v-snackbar>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'Notifier',
  props: {
    show: Boolean,
    message: { type: String, default: '' },
    type: { type: String, default: '' },
  },
  computed: {
    visible: {
      get() {
        return this.show;
      },
      set(newValue) {
        return newValue;
      },
    },
  },
  methods: {
    ...mapActions('notifier', ['reset']),
    hide() {
      this.reset();
    },
  },
};
</script>
